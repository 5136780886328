import React from 'react'
// import Layout from '../components/layout'
import Header from '../components/Headervp.js'
import Footer from '../components/Footer.js'
import Vprinter from '../components/V-printer.js'
import HeadTags from '../components/Headtags3'
import styles from '../components/vp_fpl.module.scss';
import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'
import SnackAd2 from '../components/SnackAd2'
import {Container,Row,Col} from "react-bootstrap"

const Videprinter = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}} className={styles.vbody}>
      <Row>
          <SnackAd2 ad_type = "leader" ad_num = {1}/>
      </Row>
      <Header />
      <Vprinter />
      <Container fluid>
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)

export default Videprinter
